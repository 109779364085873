var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "결재요청 정보" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [_c("q-btn-group", { attrs: { outline: "" } })],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "결재 업무명",
                              name: "approvalTypeName",
                            },
                            model: {
                              value: _vm.apprtype.approvalTypeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.apprtype, "approvalTypeName", $$v)
                              },
                              expression: "apprtype.approvalTypeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              required: true,
                              label: "결재 요청명",
                              name: "approvalRequestName",
                            },
                            model: {
                              value: _vm.reqdata.approvalRequestName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.reqdata,
                                  "approvalRequestName",
                                  $$v
                                )
                              },
                              expression: "reqdata.approvalRequestName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-12",
                      staticStyle: { "margin-top": "-15px !important" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "appr-line-detail-layer2" },
                        [
                          _c(
                            "c-card",
                            {
                              staticClass: "cardClassDetailFormLine",
                              attrs: {
                                title: "결재선",
                                topClass: "topcolor-orange",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "card-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _c("c-btn", {
                                        attrs: {
                                          label: "결재선 변경",
                                          icon: "change_circle",
                                          color: "orange",
                                        },
                                        on: { btnClicked: _vm.changeApprLine },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("template", { slot: "card-detail" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c(
                                      "draggable",
                                      _vm._b(
                                        {
                                          staticClass: "list-group",
                                          attrs: {
                                            tag: "ul",
                                            list: _vm.gridline.data,
                                            handle: ".handle",
                                          },
                                        },
                                        "draggable",
                                        _vm.dragOptions,
                                        false
                                      ),
                                      [
                                        _c(
                                          "transition-group",
                                          {
                                            attrs: {
                                              type: "transition",
                                              name: "flip-list",
                                            },
                                          },
                                          _vm._l(
                                            _vm.gridline.data,
                                            function (element, idx) {
                                              return _c(
                                                "li",
                                                {
                                                  key:
                                                    element.approvalUserId +
                                                    idx +
                                                    element.approvalKindCd,
                                                  staticClass:
                                                    "list-group-item",
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons linehandlex no-move",
                                                    },
                                                    [_vm._v("reorder")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "linetext linespan1",
                                                      class:
                                                        _vm.getLineKindColor(
                                                          element.approvalKindCd
                                                        ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.approvalKindName
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "linetext linespan2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(element.deptName)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "linetext linespan3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.approvalUserName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "c-card",
                            {
                              staticClass: "cardClassDetailFormLine",
                              attrs: {
                                title: "회람",
                                topClass: "topcolor-orange",
                              },
                            },
                            [
                              _c("template", { slot: "card-detail" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c(
                                      "draggable",
                                      _vm._b(
                                        {
                                          staticClass: "list-group",
                                          attrs: {
                                            tag: "ul",
                                            list: _vm.gridline2.data,
                                            handle: ".handle",
                                          },
                                        },
                                        "draggable",
                                        _vm.dragOptions,
                                        false
                                      ),
                                      [
                                        _c(
                                          "transition-group",
                                          {
                                            attrs: {
                                              type: "transition",
                                              name: "flip-list",
                                            },
                                          },
                                          _vm._l(
                                            _vm.gridline2.data,
                                            function (element, idx) {
                                              return _c(
                                                "li",
                                                {
                                                  key:
                                                    element.approvalUserId +
                                                    idx +
                                                    element.approvalKindCd,
                                                  staticClass:
                                                    "list-group-item",
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons linehandlex no-move",
                                                    },
                                                    [_vm._v("reorder")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "linetext linespan1",
                                                      class:
                                                        _vm.getLineKindColor(
                                                          element.approvalKindCd
                                                        ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.approvalKindName
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "linetext linespan2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(element.deptName)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "linetext linespan3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.approvalUserName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm displayBlockCard",
                attrs: {
                  title: "결재 상세내용",
                  bgClass: "purple",
                  titleClass: "white",
                },
              },
              [
                _c(
                  "template",
                  { slot: "card-detail" },
                  [
                    _c(_vm.component, {
                      tag: "component",
                      attrs: { popupParam: _vm.popupParam.approvalParamValue },
                      on: {
                        "update:popupParam": function ($event) {
                          return _vm.$set(
                            _vm.popupParam,
                            "approvalParamValue",
                            $event
                          )
                        },
                        "update:popup-param": function ($event) {
                          return _vm.$set(
                            _vm.popupParam,
                            "approvalParamValue",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.dialogShow,
            callback: function ($$v) {
              _vm.dialogShow = $$v
            },
            expression: "dialogShow",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "350px" } },
            [
              _c(
                "q-form",
                { ref: "editForm" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "서명란" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.editable,
                                    expression: "editable",
                                  },
                                ],
                                attrs: { label: "결재요청", icon: "save" },
                                on: { btnClicked: _vm.saveDialog },
                              }),
                              _c("c-btn", {
                                attrs: {
                                  label: "지우기",
                                  color: "red",
                                  showLoading: false,
                                },
                                on: { btnClicked: _vm.eraseSignature },
                              }),
                              _c("c-btn", {
                                attrs: { label: "취소", icon: "cancel" },
                                on: { btnClicked: _vm.closeDialog },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-sm-12" },
                            [
                              _c("VueSignaturePad", {
                                ref: "signaturePad",
                                attrs: { width: "300px", height: "300px" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _vm.editable
                  ? _c("c-btn", {
                      attrs: {
                        flat: true,
                        label: "결재요청",
                        icon: "check",
                        color: "purple",
                      },
                      on: { btnClicked: _vm.saveApprRequest },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }